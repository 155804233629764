import React from "react"
import Awkward from "../svg/awkwardturtle.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container flex flex-col min-h-screen align-center">
      <div className="flex justify-center">
        <Awkward/>
      </div>
      <h1 
      className="my-3 font-serif text-xl font-semibold text-center \
                      text-gray-700">
        This is Awkward...</h1>
      <p className="text-center text-gray-700 font-medium">There&#39;s no page here.</p>
    </div>
  </Layout>
)

export default NotFoundPage
